<template>
    <vue-html2pdf :enable-download="true" :paginate-elements-by-height="1400" :filename="name" :pdf-quality="2"
        :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait" ref="html2Pdf" :html-to-pdf-options="htmlToPdfOptions">
        <section slot="pdf-content">
            <section :key="groupIndex">
                <section>
                    <div class="header">
                        <div class="column logo">
                            <img class="topbar-logo" src="../assets/logo-giser.png" alt="Giser Uniformes" />
                        </div>
                        <div class="column title">
                            <h3>{{ title}}</h3>
                        </div>
                        <div class="column date-time">
                            <span id="datetime">
                                
                                <p>Doc: {{ groupIndex+1 }}</p>
                            </span>
                        </div>
                    </div>
                </section>
                <hr>
                <section class="container-table">
                    <br>
                    <h2>Información Contractual</h2>

                    <table class="contractual-table">
                        <tr>
                            <th>Rut Trabajador</th>
                            <td>{{ group[0].employee.rut }}</td>
                        </tr>
                        <tr>
                            <th>Nombre del Trabajador</th>
                            <td>{{ group[0].employeeName }} </td>
                        </tr>
                        <tr>
                            <th>Centro de Costos Actual</th>
                            <td>{{ group[0].employee.costCenter }}</td>
                        </tr>
                        <tr>
                            <th>Cargo</th>
                            <td>{{ group[0].employee.position }}</td>
                        </tr>
                        <tr>
                            <th>Fecha de Entrega</th>
                            <td>{{ inf }}</td>
                        </tr>
                    </table>
                    <br>
                    <h3>Detalle Entrega de Uniforme</h3>

                    <table class="uniform-table">
                        <tr>
                            <th>Prenda</th>
                            <th>Cantidad</th>
                            <th>Talla</th>
                        </tr>
                        <tr v-for="item in group">
                            <td>{{ item.product.description }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.product.size }}</td>
                        </tr>
                    </table>
                    <br>
                    <div>
                    El trabajador declara recepcionar de forma física el uniforme y se compromete a
                    cuidarlo, mantenerlo y utilizarlo correctamente según lo definido por la empresa
                    GISER S.A y por el administrador de su centro de costos.
                    </div>
                </section>
                <div v-if="showSignature">
                    <div class="firma-container">
                        <div class="linea-firma"></div>
                        <div class="nombre-firma">
                            <p>{{ group[0].employeeName }}</p>
                            <p>{{ group[0].employee.rut }}</p>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="groupIndex+1 < groupedDesserts.length" class="html2pdf__page-break page-break" /> -->
            </section>
        </section>
    </vue-html2pdf>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
export default {
    name: 'ReportEntregaPdf',
    components: {
        VueHtml2pdf,
    },
    props: {
        name: String,
        title: String,
        elementsPerPag: Number,
        headerAll: Boolean,
        showSignature: {
            type: Boolean,
            default: false
        },
        inf: String,
        headers: {
            type: Array, default: () => []
        },
        items: {
            type: Array, default: () => []
        },

    },
    data: () => ({
        groupIndex: 0,
        group: [],
        groupedPdf: [],
        htmlToPdfOptions: {
            filename: ''
        }
    }),
    methods: {
        preGeneratePdf() {
            this.groupedPdf = this.groupedDesserts();
            this.generatePage();
        },
        generatePage(){
            this.group = this.groupedPdf[this.groupIndex];
            this.htmlToPdfOptions.filename = `Formulario de Entrega ${this.group[0]?.employee?.rut || 'noRut'}`;
            setTimeout(() => {
                this.$refs.html2Pdf.generatePdf();
                this.nextPage();
            }, 1000);
        },

        nextPage() {
            setTimeout(() => {
                if(this.groupIndex + 1 <= this.groupedPdf.length){
                    this.groupIndex++;
                    this.generatePage();
                } else {
                    this.groupIndex = 0
                }
            }, 1000);
        },
        groupedDesserts() {
            const grouped = [];
            if(this.showSignature){
                const groupedByRut = this.items.reduce((acc, item) => {
                const rut = item.employee.rut;
                if (!acc[rut]) {
                    acc[rut] = [];
                }
                acc[rut].push(item);
                return acc;
                }, {});
                const subArrays = Object.values(groupedByRut);
                console.log("devAlexSub", subArrays)
                subArrays.forEach((subArray) => {
                    for (let i = 0; i < subArray.length; i += this.elementsPerPag) {
                        grouped.push(subArray.slice(i, i + this.elementsPerPag));
                    }
                });
            } else {
                for (let i = 0; i < this.items.length; i += this.elementsPerPag) {
                    grouped.push(this.items.slice(i, i + this.elementsPerPag));
                }
            }
            console.log("devAlex", grouped)
            return grouped;
        },
    },
    mounted() {
        console.log("activadoMounted", this.showSignature)
        this.group = []
        this.groupedPdf = []
        this.groupIndex = 0
    }
}
</script>
<style lang="scss">
.topbar-logo {
  max-width: 150px;
}
.combo-parametros {
  .v-select__selections {
    min-height: 56px !important;
  }
}
.theme--dark.v-chip.default {
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
}
.v-text-field__details {
  display: none;
}

.custom-font {
  font-family: 'Your Font Family', sans-serif;
  font-size: 16px; /* ajusta el tamaño de la fuente según tus necesidades */
  text-transform: uppercase;
}

.header {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.footer-pdf {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}

.column {
  flex: 1;
}

.logo {
  text-align: left;
}

.title {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-time {
  text-align: center;
}
/* Estilo para el contenedor de la firma */
.firma-container {
            margin-top: 3cm; /* Distancia de 4 cm desde el último div */
            text-align: center; /* Centrar la línea y el nombre */
        }

        /* Estilo para la línea de firma */
        .linea-firma {
            width: 200px; /* Ancho de la línea */
            border-bottom: 1px solid black; /* Línea negra */
            margin: 0 auto; /* Centrar la línea */
        }

        /* Estilo para el nombre debajo de la línea */
        .nombre-firma {
            margin-top: 10px; /* Espacio entre la línea y el nombre */
            font-family: Arial, sans-serif; /* Fuente del nombre */
            font-size: 14px; /* Tamaño de la fuente */
        }

        .contractual-table {
            width: 100%;
            border-collapse: collapse;
        }
        .contractual-table, .contractual-table th, .contractual-table td {
            border: 1px solid black;
        }
        .contractual-table th, .contractual-table td {
            padding: 8px;
            text-align: left;
        }
        .contractual-table th {
            background-color: #f2f2f2;
        }

        .uniform-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        .uniform-table, .uniform-table th, .uniform-table td {
            border: 1px solid black;
        }
        .uniform-table th, .uniform-table td {
            padding: 8px;
            text-align: left;
        }
        .uniform-table th {
            background-color: #f2f2f2;
        }

        .container-table {
            margin-left: 20px;
            margin-right: 20px;
        }

        .page-break {
            page-break-after: always;
            margin-top: 0;
        }
</style>